/* eslint-disable no-restricted-syntax */
import Vue from 'vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  shortcutDays,
  shortcutMonths,
  DOMAIN_SETTINGS,
} from '@/helpers/consts';
import { formatISO } from 'date-fns';
import { APP_SETTINGS } from '@/app.config';

export const domain = (function () {
  const localStorageTheme = localStorage.getItem('theme');

  if (localStorageTheme && DOMAIN_SETTINGS[localStorageTheme]) {
    return DOMAIN_SETTINGS[localStorageTheme];
  }
  return DOMAIN_SETTINGS[APP_SETTINGS || 'gostelemed'];
}());

export function headers() {
  return { 'x-vc-project': 'gostelemed.doctor' };
}

export function getPastDaysFromDayCount(daysCount, endDate) {
  const week = [];
  const days = [...shortcutDays];

  for (let i = 0; i < daysCount; i += 1) {
    const date = endDate ? new Date(endDate) : new Date(Date.now());
    const tomorrow = date.setDate(date.getDate() - week.length);
    // const tomorrowDate = new Date(new Date(tomorrow).setHours(0, 0, 0, 0));
    const tomorrowDate = new Date(tomorrow);

    week.push({
      rawDate: tomorrowDate,
      formattedDate: new Intl.DateTimeFormat('ru-Ru', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
        .format(tomorrowDate)
        .split('.')
        .reverse()
        .join('-'),
      day: tomorrowDate.getDay(),
      date: tomorrowDate.getDate(),
      dayName: days[tomorrowDate.getDay()],
      slots: null,
      monthName: shortcutMonths[tomorrowDate.getMonth()],
    });
  }
  return week;
}

export function removeNamespaces(types) {
  const newTypes = {};

  const keys = Object.keys(types);

  keys.forEach((namespace) => {
    newTypes[namespace] = types[namespace].split('/')[1] || types[namespace];
  });

  return newTypes;
}

export async function saveFile(data, options = {}) {
  const {
    name = 'file',
    target = '_blank',
    open = false,
  } = options;

  let url;

  if (typeof data !== 'string') {
    url = window.URL.createObjectURL(data);
  } else {
    url = data;
  }

  if (open) {
    const childWindow = window.open(url);

    await new Promise((resolve) => setTimeout(resolve, 500));

    if (childWindow && (typeof childWindow.closed !== 'undefined')) {
      return;
    }

    // eslint-disable-next-line no-alert
    alert('Кажется Ваш браузер заблокировал документ, поэтому мы скачиваем его.');
  }

  const link = document.createElement('A');

  link.download = name;
  link.href = url;
  link.target = target;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function showMessage(option) {
  let defaultDuration = 3000;

  switch (option.type) {
    case 'error':
      defaultDuration = -1;
      break;
    case 'warning':
      defaultDuration = 6000;
      break;
    default:
  }

  Vue.notify({
    group: 'global-notifications',
    ignoreDuplicates: true,
    closeOnClick: false,
    type: option.type,
    title: option.title,
    text: option.message,
    duration: option.duration || defaultDuration,
  });
}

export function clearMessages() {
  Vue.notify({
    group: 'global-notifications',
    clean: true,
  });
}

export function convertObjToQueryParams(obj = {}) {
  const query = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      value.forEach((valueFromArray) => {
        query.append(key, valueFromArray);
      });
    } else {
      query.append(key, value);
    }
  });

  return query;
}

export function appendFormData(formData, data, name) {
  // eslint-disable-next-line no-param-reassign
  name = name || '';
  if (typeof data === 'object' && !(data instanceof File)) {
    for (const dataKey in data) {
      if (name === '') {
        appendFormData(formData, data[dataKey], dataKey);
      } else {
        appendFormData(formData, data[dataKey], `${name}[${dataKey}]`);
      }
    }
  } else {
    formData.append(name, data);
  }
}

export function createCoordMask(type) {
  return createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    integerLimit: type === 'latitude' ? 2 : 3,
    requireDecimal: true,
    decimalLimit: 15,
    allowNegative: true,
  });
}

export function alphabeticSort(a, b) {
  const stringA = a.toLowerCase();
  const stringB = b.toLowerCase();
  if (stringA < stringB) { return -1; }// sort string ascending
  if (stringA > stringB) { return 1; }
  return 0; // default return value (no sorting)
}

export function getFileExtension(fileName) {
  const matchedExtension = fileName.match(/\.([0-9a-z]+)$/i);

  return matchedExtension ? matchedExtension[1] : '';
}

export function formatTimeZone(timeZone) {
  return `UTC${timeZone < 0 ? `${timeZone}` : `+${timeZone}`}`;
}

export function getClientTimezoneOffset() {
  const hoursOffset = new Date().getTimezoneOffset() / 60;
  return hoursOffset === 0 ? hoursOffset : hoursOffset * -1;
}

export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || '')}${expires}; path=/`;
}

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function emailValidate(email) {
  // eslint-disable-next-line max-len
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(String(email).toLowerCase());
}

export function calculateAge(birthday) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function onBInputDatePaste(e) {
  const dateArray = e.clipboardData.getData('text')
    .split('/')
    .join('.')
    .split('-')
    .join('.')
    .split('.');

  const day = dateArray[0];
  const month = dateArray[1];
  const year = dateArray[2];

  const date = [year, month, day].join('-');
  e.target.value = date;
}

export function dateDiffInDays(a, b) {
  console.log(a, b);
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY) + 1;
}

export const declOfNum = (number, words) => words[
  number % 100 > 4 && number % 100 < 20
    ? 2
    : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
];

export const getSiblings = (rootNode, startNode, endNode) => {
  const result = [];
  let startPushed = false;
  let endPushed = false;

  const { children } = rootNode;
  for (const child of children) {
    if (child === startNode) {
      result.push(child);
      startPushed = true;
    } else if (child === endNode) {
      result.push(child);
      endPushed = true;
    } else if (startPushed && !endPushed) {
      result.push(child);
    }
  }

  return result;
};

export const convertBlobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const toISOStringWithTimezone = (date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return `${date.getFullYear()
  }-${pad(date.getMonth() + 1)
  }-${pad(date.getDate())
  }T${pad(date.getHours())
  }:${pad(date.getMinutes())
  }:${pad(date.getSeconds())
  }${diff}${pad(tzOffset / 60)
  }:${pad(tzOffset % 60)}`;
};

export const createBlobFromArrayBuffer = (buffer, mimeType) => new Blob([buffer], { type: mimeType });

export function validateInn(value) {
  // первая цифра ИНН может быть нулём, поэтому он не может быть числом
  if (typeof value !== 'string') {
    console.error(`ИНН должен быть строкой. Получено: ${typeof value}`);
    return false;
  }

  // ИНН юрлиц - 10 символов, ИП - 12 символов
  if (value.length !== 10 && value.length !== 12) {
    console.error(`Некорректная длинна ИНН: ${value.length}`);
    return false;
  }

  // преобразуем строку в массив цифр
  const arNumbers = value.split('');
  if (arNumbers.length === 0) {
    console.error('Не удалось разобрать строку в массив символов');
    return false;
  }

  // проверим что у нас в массиве только цифры
  for (const symbol of arNumbers) {
    if (Number.isNaN(Number(symbol))) {
      console.error(`Некорректный символ "${symbol}" в ИНН`);
      return false;
    }
  }

  // формула для юрлиц и ИП отличается
  if (arNumbers.length === 10) {
    // переменная для итоговой суммы
    // каждую цифру мы умножаем на свой коэффициент
    // а потом получаем остаток от деления на 11 и на 10
    const checkSum = ((
      2 * arNumbers[0]
      + 4 * arNumbers[1]
      + 10 * arNumbers[2]
      + 3 * arNumbers[3]
      + 5 * arNumbers[4]
      + 9 * arNumbers[5]
      + 4 * arNumbers[6]
      + 6 * arNumbers[7]
      + 8 * arNumbers[8]
    ) % 11) % 10;

    // проверяем что десятый символ ИНН совпадает с контрольной суммой
    if (checkSum === Number(arNumbers[9])) {
      return true;
    }
    console.error(
      `Контрольная сумма не совпала с десятым символом ${checkSum} != ${arNumbers[9]}`,
    );
    return false;

    // код для ИП
  } if (arNumbers.length === 12) {
    // в этом случае будет две контрольные суммы
    const checkSumOne = ((
      7 * arNumbers[0]
      + 2 * arNumbers[1]
      + 4 * arNumbers[2]
      + 10 * arNumbers[3]
      + 3 * arNumbers[4]
      + 5 * arNumbers[5]
      + 9 * arNumbers[6]
      + 4 * arNumbers[7]
      + 6 * arNumbers[8]
      + 8 * arNumbers[9]
    ) % 11) % 10;

    const checkSumTwo = ((
      3 * arNumbers[0]
      + 7 * arNumbers[1]
      + 2 * arNumbers[2]
      + 4 * arNumbers[3]
      + 10 * arNumbers[4]
      + 3 * arNumbers[5]
      + 5 * arNumbers[6]
      + 9 * arNumbers[7]
      + 4 * arNumbers[8]
      + 6 * arNumbers[9]
      + 8 * arNumbers[10]
    ) % 11) % 10;

    // в этом случае мы проверяем 11 и 12 символы
    if (checkSumOne === Number(arNumbers[10])
      && checkSumTwo === Number(arNumbers[11])) {
      return true;
    } if (checkSumOne !== Number(arNumbers[10])) {
      console.error(
        `Первая контрольная сумма не совпала
с одиннадцатым символом ${checkSumOne} != ${arNumbers[10]}`,
      );
      return false;
    } if (checkSumTwo !== Number(arNumbers[11])) {
      console.error(
        `Вторая контрольная сумма не совпала
с двенадцатым символом ${checkSumTwo} != ${arNumbers[11]}`,
      );
      return false;
    }
  }
}

export function validateOgrn(chekedValue) {
  // дальше работаем со строкой
  chekedValue += '';

  // для ОГРН в 13 знаков
  if (chekedValue.length === 13
    && (chekedValue.slice(12, 13) === (`${(chekedValue.slice(0, -1)) % 11}`).slice(-1))) {
    return true;
  }
  if (chekedValue.length === 15
    && (chekedValue.slice(14, 15) === (`${(chekedValue.slice(0, -1)) % 13}`).slice(-1))) {
    // для ОГРН в 15 знаков

    return true;
  }
  return false;
}

export function validateKpp(kpp) {
  if (typeof kpp === 'number') {
    kpp = kpp.toString();
  } else if (typeof kpp !== 'string') {
    kpp = '';
  }
  if (!kpp.length) {
    console.error('КПП пуст');
    return false;
  }
  if (kpp.length !== 9) {
    console.error('КПП может состоять только из 9 знаков (цифр или заглавных букв латинского алфавита от A до Z)');
    return false;
  }
  if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
    console.error('Неправильный формат КПП');
    return false;
  }

  return true;
}

export function consultationControlText(status) {
  switch (status) {
    case 1:
      return 'В ожидании';
    case 2:
      return 'Закрыто';
    case 3:
      return 'Просрочено';

    default:
      return 'Обработать';
  }
}

// удаление тайм-зоны
export function dateWithoutTimeZone(date) {
  // Находим индекс точки . Пример - 2022-08-24T11:34:02.929194z
  const dateSplit = date.split('');
  const pointIndex = dateSplit.findIndex((item) => item === '.');

  if (pointIndex !== -1) {
    return date.substring(0, pointIndex); // возвращаем дату до точки
  }

  return date;
}

export function isoWithoutTimeZone(date) {
  let newDate = formatISO(date);
  newDate = newDate.substring(0, newDate.length - 6);
  return newDate;
}
// удаление времени в дате полностью
export function dateWithoutTime(date) {
  // Находим индекс "T" . Пример - 2022-08-24T11:34:02.929194z
  const dateSplit = date.split('');
  const pointIndex = dateSplit.findIndex((item) => item === 'T');

  if (pointIndex !== -1) {
    return date.substring(0, pointIndex); // возвращаем дату до точки
  }

  return date;
}

export const hasRussian = (val) => /[а-яёА-ЯЁ]/.test(val); // только русские буквы, пробел и дефис
export const hasRussianName = (val) => /^[а-яёА-ЯЁ-]+$/.test(val); // только русские буквы, пробел, дефис скобки и точку

export const getAccumulateStatistic = (keys, data) => {
  let percentage = 0;
  let quantity = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const key in data.taskCounters) {
    if (keys.includes(key)) {
      percentage += data.taskCounters[key].percentage;
      quantity += data.taskCounters[key].quantity;
    }
  }

  return {
    percentage,
    quantity,
  };
};

export const copyTextToBuffer = (payload) => {
  navigator.clipboard.writeText(payload).then(() => {
    showMessage({
      type: 'success',
      title: 'ID скопирован в буфер обмена',
      message: 'Вставьте ID, куда вам удобно (ctrl+v)',
    });
  });
};

export const validateRequiredFields = (object, requiredFields) => {
  const errorFields = Object.entries(requiredFields).reduce((fields, [key, value]) => {
    if (!object[key]) {
      fields.push(value);
    }
    return fields;
  }, []);
  return {
    success: !errorFields.length,
    errorFields,
  };
};

export const decodeArrayBufferToJson = (buffer) => JSON.parse(new TextDecoder().decode(buffer));

export function showErrorMessages(response) {
  const { status = {}, data = {} } = response;
  // const { errors = {} } = data;
  const { message } = response.data;

  console.log('message', message);

  /* errorId и requestId ошибки */
  if (data.data?.errorId || data.data?.requestId) {
    const errorId = data.data?.errorId;
    const requestId = data.data?.requestId;

    let text = '';
    if (errorId) {
      text += `ErrorID - ${errorId} <br/>`;
    }

    if (requestId) {
      text += `RequestID - ${requestId}`;
    }

    showMessage({ type: 'error', title: 'Данные о ошибке', message: text });
  }

  if (status === 500 && message) {
    showMessage({ type: 'error', title: 'Ошибка при выполнении запроса', message });
  }

  /* Нет ответа от сервера */
  if (!Object.keys(data).length || status === 500) {
    showMessage({ type: 'error', title: 'Ошибка соединения', message: 'Нет ответа от сервера' });
  }

  // if (typeof data === 'string') {
  //   showMessage({ type: 'error', title: 'Ошибка', message: data });
  // } else if (data.title) {
  //   showMessage({ type: 'error', title: 'Ошибка', message: data.title });
  // } else if (data.Message) {
  //   showMessage({ type: 'error', title: 'Ошибка', message: data.Message });
  // } else if (Object.entries(errors).length) {
  //   for (const [title, errorArray] of Object.entries(errors)) {
  //     const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');
  //
  //     showMessage({ type: 'error', title, message: errorsString });
  //   }
  // } else {
  //   for (const [title, errorArray] of Object.entries(data)) {
  //     if (Array.isArray(errorArray)) {
  //       const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');
  //       showMessage({ type: 'error', title, message: errorsString });
  //     }
  //   }
  // }

  if (status === 403) {
    showMessage({ type: 'error', title: 'Ошибка', message: 'Отсутствуют необходимые права' });
  }
}
